import React, { useMemo } from 'react'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { FormattedMessage, IntlContextConsumer } from 'gatsby-plugin-intl'

import { Container, Heading, Text, Button } from '_atoms'
import { CustomAccordion } from '_components/molecules'

import ViewportObserver from '../viewport-observer'

import styles from './styles.module.css'

const intlBreakLineValues = {
  u: (...chunks) => <u>{chunks}</u>,
  strong: (...chunks) => <strong>{chunks}</strong>,
  span: (...chunks) => (
    <span>
      {chunks}
      <br />
    </span>
  ),
}

const SolvingRealProblems = ({ scroll, onViewport }) => {
  const accordionData = useMemo(() => {
    return [
      {
        title: <FormattedMessage id={`home.solvingRealProblems.accordion.item-${0}.title`} />,
        content: (
          <FormattedMessage id={`home.solvingRealProblems.accordion.item-${0}.description`} />
        ),
        href: '/services/staff-augmentation',
      },
      {
        title: <FormattedMessage id={`home.solvingRealProblems.accordion.item-${1}.title`} />,
        content: (
          <FormattedMessage id={`home.solvingRealProblems.accordion.item-${1}.description`} />
        ),
        href: '/services/dedicated-team',
      },
      {
        title: <FormattedMessage id={`home.solvingRealProblems.accordion.item-${2}.title`} />,
        content: (
          <FormattedMessage id={`home.solvingRealProblems.accordion.item-${2}.description`} />
        ),

        href: '/services/software-outsourcing',
      },
    ]
  }, [])

  return (
    <section id="solving-real-problems-section" className={styles.solvingRealProblems}>
      <Container>
        <div className={styles.solvingRealProblemsContainer}>
          <div className={styles.solvingRealProblemsContent}>
            <Fade distance="20%" delay={100} bottom>
              <div
                className="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="14"
                data-height="50"
                data-nofollow="true"
                data-expandifr="true"
                data-scale="100"
                data-clutchcompany-id="25248"
              />
            </Fade>
            <Fade distance="20%" delay={200} bottom>
              <Heading type="h2" className={styles.solvingRealProblemsContentTitle}>
                <FormattedMessage
                  values={intlBreakLineValues}
                  id="home.solvingRealProblems.title"
                />
                <span className={styles.squared}>.</span>
              </Heading>
            </Fade>

            <Fade distance="20%" delay={300} bottom>
              <Button
                href="/contact/"
                className={styles.solvingRealProblemsButton}
                small
                type="primaryOutline"
              >
                <FormattedMessage id="home.solvingRealProblems.cta" />
              </Button>
            </Fade>
          </div>
          <div className={styles.solvingRealProblemsAccordion}>
            <Fade distance="20%" delay={100} right>
              <Text size="24" color="dark" className={styles.solvingRealProblemsDescription}>
                <FormattedMessage id="home.solvingRealProblems.description" />
              </Text>
            </Fade>

            <Fade distance="20%" delay={200} right>
              <div className={styles.solvingRealProblemsAccordionContainer}>
                {accordionData &&
                  accordionData.map((data, i) => {
                    const index = i
                    const key = `accordion-item-${index}`
                    return (
                      <IntlContextConsumer>
                        {() => {
                          return (
                            <>
                              {index !== 0 && <hr />}
                              <CustomAccordion
                                startsOpen={index === 0}
                                key={key}
                                title={data.title}
                                href={data.href}
                                content={data.content}
                              />
                            </>
                          )
                        }}
                      </IntlContextConsumer>
                    )
                  })}
              </div>
            </Fade>
          </div>
        </div>
      </Container>

      {!!scroll && <ViewportObserver scroll={scroll} onViewport={onViewport} />}
    </section>
  )
}

SolvingRealProblems.propTypes = {
  scroll: PropTypes.number,
  onViewport: PropTypes.func,
}

SolvingRealProblems.defaultProps = {
  scroll: undefined,
  onViewport: undefined,
}

export default SolvingRealProblems
