import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import { BlueText, Container, Heading, Text } from '_components/atoms'
import { Hero } from '_components/molecules'
import { ContactForm } from '_components/organisms/contact-form'

import styles from './styles.module.css'

export const LestChatSection = ({ hubspotFormId, interestedSelectOptions, interestFieldName }) => {
  return (
    <div className={styles.page}>
      <Hero
        title="contact.hero.title"
        subTitle="contact.hero.subtitle"
        className={styles.heroMobile}
      />

      <Container>
        <Row className={styles.pageRow}>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={styles.heroDesktop}>
              <div className={styles.heroContent}>
                <Fade distance="25%" top>
                  <Heading type="h2" color="white" className={styles.heroTitle} bold>
                    <FormattedMessage id="contact.hero.title" />
                    <BlueText light squared>
                      .
                    </BlueText>
                  </Heading>
                </Fade>
                <Fade distance="25%" bottom>
                  <Text size="24" color="white">
                    <FormattedMessage id="contact.hero.subtitle" />
                  </Text>
                </Fade>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} id="contactForm">
            <ContactForm
              formId={hubspotFormId}
              interestedOptions={interestedSelectOptions}
              interestedFieldName={interestFieldName}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

LestChatSection.propTypes = {
  hubspotFormId: PropTypes.string,
  interestedSelectOptions: PropTypes.arrayOf({}),
  interestFieldName: PropTypes.string,
}

LestChatSection.defaultProps = {
  hubspotFormId: undefined,
  interestedSelectOptions: undefined,
  interestFieldName: undefined,
}
