import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'
import Fade from 'react-reveal/Fade'

import { userLocation } from '_services/location'
import { sendContactForm } from '_services/contact'
import { Button, Input, Select, Text } from '_atoms'
import LaptopMessage from '_images/svgs/laptop-message.svg'
import { scrollTo } from '_utils/scroll'

import styles from './styles.module.css'

export const ContactForm = ({ formId, interestedOptions, interestedFieldName }) => {
  const intl = useIntl()
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [interest, setInterest] = useState({ value: '' })
  const [projectBudget, setProjectBudget] = useState({ value: '' })
  const [location, setLocation] = useState(null)
  const [ipAddress, setIpAddress] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(false)

  const getIntlText = id => intl.formatMessage({ id })

  const interestFieldName = interestedFieldName || 'interest'

  const FORM_FIELDS = [
    {
      name: 'email',
      placeholder: getIntlText('contact.fields.email'),
      required: true,
      type: 'email',
    },
    { name: 'howDidYouHear', placeholder: getIntlText('contact.fields.howDidYouHear') },
  ]

  const FORM_OPTIONS = interestedOptions || [
    {
      value: '',
      label: getIntlText('contact.interests.title'),
    },
    {
      value: 'product-definition',
      label: getIntlText('contact.interests.product'),
      project: true,
    },
    {
      value: 'dev-ux',
      label: getIntlText('contact.interests.design'),
      project: true,
    },
    {
      value: 'dev-mobile',
      label: getIntlText('contact.interests.mobile'),
      project: true,
    },
    {
      value: 'dev-web',
      label: getIntlText('contact.interests.web'),
      project: true,
    },
    {
      value: 'next-gen',
      label: getIntlText('contact.interests.nextGen'),
      project: true,
    },
    {
      value: 'work',
      label: getIntlText('contact.interests.work'),
    },
    {
      value: 'other',
      label: getIntlText('contact.interests.other'),
    },
  ]

  const FORM_PROJECT_BUDGET = [
    { value: '', label: getIntlText('contact.fields.projectBudget') },
    { value: getIntlText('contact.budget.first'), label: getIntlText('contact.budget.first') },
    { value: getIntlText('contact.budget.second'), label: getIntlText('contact.budget.second') },
    { value: getIntlText('contact.budget.third'), label: getIntlText('contact.budget.third') },
    { value: getIntlText('contact.budget.fourth'), label: getIntlText('contact.budget.fourth') },
  ]

  const setFormValue = field => ({ target }) => {
    const { value } = target
    if (!formValues[field] && formErrors[field]) setFormErrors({ ...formErrors, [field]: false })

    setFormValues({ ...formValues, [field]: value })
  }

  const handleRequiredSelect = ({ target }, name, formOptions) => {
    const { value } = target
    const selected = formOptions.find(({ value: currentValue }) => currentValue === value)

    if (value && formErrors[name]) setFormErrors({ ...formErrors, [name]: false })

    if (name === 'interest') setInterest(selected)
    if (name === 'expected_budget') setProjectBudget(selected)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const errors = {}

    const formData = {
      [interestFieldName]: interest.label,
      expected_budget: projectBudget.label,
      location,
    }


    if (interest.project) formData.expected_budget = projectBudget.label

    FORM_FIELDS.forEach(({ name, required }) => {
      formData[name] = formValues[name]
      if (required && !formValues[name]) errors[name] = true
    })

    if (!interest.value) errors.interest = true
    if (interest.project && !projectBudget.value) errors.expected_budget = true
    if (interest.project && !formValues.project_description) errors.project_description = true

    setFormErrors(errors)

    if (Object.keys(errors).length > 0) return
  

    if (interest.project) {
      formData.expected_budget = projectBudget.value
      formData.project_description = formValues.project_description
    } else {
      formData.expected_budget = ''
    }

    if (!formData.firstname) {
      formData.firstname = formValues.firstname || ''
    }
    if (!formData.lastname) {
      formData.lastname = formValues.lastname || ''
    }
    if (!formData.phone) {
      formData.phone = formValues.phone || ''
    }

    if (!formData.company) {
      formData.company = formValues.company || ''
    }

    if (!formData.howDidYouHear) {
      formData.howDidYouHear = formValues.howDidYouHear || ''
    }

    if (!formData.location) {
      formData.location = ''
    }

    if (interest.value === 'work' || interest.value === 'other')
      formData.message = formValues.message || ''

    if (!formData.hidden) {
      formData.hidden = ''
    }

    // A side effect was created by an external provider populating this field outside of the React loop
    const mktCookies = document.querySelector('.mkt_cookies')
    if (mktCookies) {
      formData.mkt_cookies = mktCookies.value
    }

    // Spam protection - bots will fill the hidden-field with a value
    // and we don't want to submit a form with this value filled.
    const hiddenField = document.getElementById('hidden-field')

    if (hiddenField.value) {
      return
    }

    sendContactForm(formData, ipAddress, formId)
      .then(() => {
        setFormSubmitted(true)

        window.dataLayer.push({
          event: 'generate_lead',
        })

        window.lintrk('track', { conversion_id: 14139633 })
      })
      .catch((err) => {

        setFormError(true)
        return setFormSubmitted(false)
      })
      .then(() => {
        // Scroll to the top of the contactForm element on submission
        scrollTo('contactForm')
      })
  }

  useEffect(() => {
    userLocation().then(locationData => {
      setLocation(locationData.locationString)
      setIpAddress(locationData.ipAddress)
    })
  }, [])

  const renderInputs = () => (
    <form id="contactFormId" onSubmit={handleSubmit} className={styles.formContent}>
      
      <input type="hidden" name="mkt_cookies" id="mkt_cookies" className="mkt_cookies" />
      <div className={styles.formFieldGroup}>
          <div className={styles.formField}>
            <Input
              id="firstname"
              label={getIntlText('contact.fields.firstName')}
              required
              type={"text"}
              key={"firstname"}
              name={"firstname"}
              value={formValues["firstname"] || ''}
              placeholder={getIntlText('contact.fields.firstName')}
              className={styles.formInput}
              onChange={setFormValue('firstname')}
              error={formErrors.firstname}
            />
          </div>
          <div className={styles.formField}>
            <Input
              id="lastname"
              label={getIntlText('contact.fields.lastName')}
              required
              type={"text"}
              key={"lastname"}
              name={"lastname"}
              value={formValues["lastname"] || ''}
              placeholder={getIntlText('contact.fields.lastName')}
              className={styles.formInput}
              onChange={setFormValue('lastname')}
              error={formErrors.lastname}
            />
          </div>
        </div>
        <div key={"email"} className={styles.formField}>
          <Input
            id={"email"}
            label={getIntlText('contact.fields.email')}
            required
            type={"email"}
            key={"email"}
            name={"email"}
            className={styles.formInput}
            value={formValues["email"] || ''}
            error={formErrors["email"]}
            onChange={setFormValue("email")}
            placeholder={getIntlText('contact.fields.email')}
          />
        </div>
        <div className={styles.formFieldGroup}>
        <div className={styles.formField}>
          <Input
            id={"company"}
            type={"text"}
            key={"company"}
            name={"company"}
            label={getIntlText('contact.fields.company')}
            value={formValues["company"]}
            placeholder={getIntlText('contact.fields.company')}
            className={styles.formInput}
            onChange={setFormValue('company')}
            required
          />
        </div>
        <div className={styles.formField}>
          <Input
            id={"phone"}
            key={"phone"}
            name={"phone"}
            label={getIntlText('contact.fields.phone')}
            value={formValues["phone"]}
            placeholder={getIntlText('contact.fields.phone')}
            className={styles.formInput}
            onChange={setFormValue('phone')}
            required
          />
        </div>
      </div>
      <div key={"howDidYouHear"} className={styles.formField}>
      <Input
            id={"howDidYouHear"}
            label={getIntlText('contact.fields.howDidYouHear')}
            type={'text'}
            key={"howDidYouHear"}
            name={"howDidYouHear"}
            className={styles.formInput}
            value={formValues["howDidYouHear"]}
            error={formErrors["howDidYouHear"]}
            onChange={setFormValue("howDidYouHear")}
            placeholder={getIntlText('contact.fields.howDidYouHear')}

          />
        </div>
      <div className={styles.formField}>
        <Select
          id="Interest"
          name="Interest"
          label={getIntlText('contact.interests.title')}
          value={interest.value}
          placeholder={getIntlText('contact.interests.title')}
          options={FORM_OPTIONS}
          onChange={e => handleRequiredSelect(e, 'interest', FORM_OPTIONS)}
          error={formErrors.interest}
          required
        />
      </div>
      {interest.project && (
        <>
          <div className={styles.formField}>
            <Select
              id="Budget"
              name="Budget"
              label={getIntlText('contact.fields.projectBudget')}
              className={styles.formInput}
              value={projectBudget.value}
              placeholder={getIntlText('contact.fields.projectBudget')}
              options={FORM_PROJECT_BUDGET}
              onChange={e => handleRequiredSelect(e, 'expected_budget', FORM_PROJECT_BUDGET)}
              error={formErrors.expected_budget}
              required
            />
          </div>
          <div className={styles.formField}>
            <Input
              id="Project-Description"
              name="Project-Description"
              label={getIntlText('contact.fields.projectAbout')}
              textarea
              required
              value={formValues.project_description}
              placeholder={getIntlText('contact.fields.projectAbout')}
              className={styles.formInput}
              onChange={setFormValue('project_description')}
              error={formErrors.project_description}
            />
          </div>
        </>
      )}
      {interest.value === 'work' && (
        <Text size="18" className={styles.formNote}>
          <FormattedMessage id="contact.work.text" />{' '}
          <Link className={styles.formLink} to="/careers">
            <FormattedMessage id="contact.work.page" />
          </Link>{' '}
          <FormattedMessage id="contact.work.and" />{' '}
          <Link className={styles.formLink} to="/careers/#positions">
            <FormattedMessage id="contact.work.positions" />
          </Link>
        </Text>
      )}
      {interest.value && !interest.project && (
        <div className={styles.formField}>
          <Input
            id="message"
            label={getIntlText('contact.fields.message')}
            textarea
            name="message"
            value={formValues.message || ''}
            placeholder={getIntlText('contact.fields.message')}
            className={styles.formInput}
            onChange={setFormValue('message')}
          />
        </div>
      )}
      <div className={styles.formHiddenField}>
        <Input
          id="hidden-field"
          label=""
          name="hidden"
          value={formValues.hidden || ''}
          placeholder=""
          onChange={setFormValue('hidden')}
        />
      </div>
      <Button id="contactSubmitId" type="primary" htmlType="submit" className={styles.formSubmit}>
        <FormattedMessage id="contact.submit" />
      </Button>
    </form>
  )

  const showForm = () => {
    setFormError(false)
    setFormSubmitted(false)
  }

  const renderFormSubmitted = () => (
    <Fade distance="25%" top>
      <div className={styles.submitted}>
        <Text size="32" color="dark" bold className={styles.submittedTitle}>
          <FormattedMessage id="contact.submitted.title" />
        </Text>

        <Text size="32" color="dark" className={styles.submittedSubtitle}>
          <FormattedMessage id="contact.submitted.subtitle" />
          <span className={styles.squared}>!</span>
        </Text>

        <LaptopMessage className={styles.submittedImage} />
      </div>
    </Fade>
  )

  const renderFormError = () => (
    <Fade distance="25%" top>
      <div className={styles.submitted}>
        <Text size="32" color="dark" bold className={styles.submittedTitle}>
          <FormattedMessage id="contact.error.title" />
        </Text>

        <Text size="24" color="dark" className={styles.submittedDescription}>
          <FormattedMessage id="contact.error.description" />
        </Text>

        <Button type="primary" onClick={showForm}>
          <FormattedMessage id="contact.error.button" />
        </Button>
      </div>
    </Fade>
  )

  return (
    <div className={styles.form}>
      {formError && renderFormError()}
      {formSubmitted && !formError && renderFormSubmitted()}
      {!formSubmitted && !formError && renderInputs()}
    </div>
  )
}

ContactForm.propTypes = {
  formId: PropTypes.string,
  interestedOptions: PropTypes.arrayOf({}),
  interestedFieldName: PropTypes.string,
}

ContactForm.defaultProps = {
  formId: undefined,
  interestedOptions: undefined,
  interestedFieldName: undefined,
}
